<template>
  <div class="page-news-add">
    <el-alert
      v-copy="model.tempFile.response.data"
      v-if="model.tempFile"
      type="success"
      effect="dark"
      style="margin-bottom: 20px"
    >
      <template #title>
        <p>上传成功, 点击此处可复制该链接{{ model.tempFile.response.data }}</p>
      </template>
    </el-alert>
    <pro-form
      label-width="120px"
      label-suffix=":"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitButtonProps: {
          loading
        },
        submitText: '保存',
        resetText: '返回'
      }"
      @submit="submit"
      @reset="reset"
    ></pro-form>
  </div>
</template>

<script>
import { uid } from 'uid'
import Upload from '@/components/upload'
import Editor from '@/components/editor'
import { localStore } from '@/utils/store'
import * as ConstantRouter from '@/constant/router'

export default {
  data() {
    return {
      loading: false,
      id: this.$route.params.id,
      jsList: [],
      jsLoading: false,
      model: {
        title: '',
        titleEn: '',
        jsId: undefined,
        articleType: '',
        showImgFile: null,
        showImgEnFile: null,
        videoFile: null,
        context: '',
        contextEn: '',
        articleDate: undefined,
        tempFile: null,
        contentType: 1,
        outUrl: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '新闻标题(中文)',
            prop: 'title'
          },
          style: {
            width: '360px'
          }
        },
        {
          type: 'input',
          formItemProps: {
            label: '新闻标题(英文)',
            prop: 'titleEn'
          },
          style: {
            width: '360px'
          }
        },
        {
          formItemProps: {
            style: 'width: 460px',
            label: '选择竞赛',
            prop: 'jsId'
          },
          render: (h, prop, field, model) => {
            return (
              <el-select
                style="width: 360px;"
                v-model={model[prop]}
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键词"
                remoteMethod={this.fetchJsList}
                onFocus={this.fetchJsListFocus}
                loading={this.jsLoading}
              >
                {this.jsList.map((item) => (
                  <el-option
                    key={item.value}
                    label={item.label}
                    value={item.value}
                  ></el-option>
                ))}
              </el-select>
            )
          }
        },
        {
          type: 'radio-group',
          formItemProps: {
            label: '文章分类',
            prop: 'articleType'
          },
          children: [
            {
              label: 1,
              text: '新闻'
            },
            {
              label: 2,
              text: '通知'
            },
            {
              label: 3,
              text: '往届作品'
            },
            {
              label: 4,
              text: '大赛专访'
            },
            {
              label: 5,
              text: '赛道简介'
            }
          ]
        },
        {
          formItemProps: {
            label: '缩略图(中文)',
            prop: 'showImgFile'
          },
          render(h, prop, field, model) {
            return (
              <Upload
                v-model={model[prop]}
                token={localStore.get('dashboard-token')}
              ></Upload>
            )
          }
        },
        {
          formItemProps: {
            label: '缩略图(英文)',
            prop: 'showImgEnFile'
          },
          render(h, prop, field, model) {
            return (
              <Upload
                v-model={model[prop]}
                token={localStore.get('dashboard-token')}
              ></Upload>
            )
          }
        },
        {
          formItemProps: {
            label: '视频',
            prop: 'videoFile'
          },
          render(h, prop, field, model) {
            return (
              <Upload
                type="video"
                v-model={model[prop]}
                token={localStore.get('dashboard-token')}
              ></Upload>
            )
          }
        },
        {
          formItemProps: {
            label: '上传文件',
            prop: 'tempFile'
          },
          render: (h, prop, field, model) => {
            return (
              <div>
                <Upload
                  v-model={model[prop]}
                  token={localStore.get('dashboard-token')}
                  type="file"
                ></Upload>
              </div>
            )
          }
        },
        {
          type: 'radio-group',
          formItemProps: {
            label: '内容类型',
            prop: 'contentType'
          },
          children: [
            {
              label: 1,
              text: '富文本'
            },
            {
              label: 2,
              text: '外部链接'
            }
          ]
        },
        {
          type: 'input',
          formItemProps: {
            label: '外部链接',
            prop: 'outUrl'
          },
          style: {
            width: '360px'
          }
        },
        {
          formItemProps: {
            label: '新闻内容(中文)',
            prop: 'context'
          },
          render(h, prop, field, model) {
            return (
              <Editor
                v-model={model[prop]}
                token={localStore.get('dashboard-token')}
              ></Editor>
            )
          }
        },
        {
          formItemProps: {
            label: '新闻内容(英文)',
            prop: 'contextEn'
          },
          render(h, prop, field, model) {
            return (
              <Editor
                v-model={model[prop]}
                token={localStore.get('dashboard-token')}
              ></Editor>
            )
          }
        },
        {
          type: 'date-picker',
          formItemProps: {
            label: '发布日期',
            prop: 'articleDate'
          },
          attributes: {
            valueFormat: 'yyyy-MM-dd'
          },
          style: {
            width: '360px'
          }
        }
      ],
      rules: {
        title: [
          { required: true, message: '请输入新闻标题(中文)', trigger: 'change' }
        ],
        titleEn: [
          { required: true, message: '请输入新闻标题(英文)', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {
    if (this.id) this.fetchDetail()
  },
  methods: {
    async fetchJsList(keyword) {
      this.jsLoading = true
      const [err, res] = await this.$serve.jsRecord.getPageList({
        data: {
          pageSize: 100,
          keyword
        }
      })
      this.jsLoading = false
      if (err) return
      this.jsList = res.records.map((item) => ({
        label: item.name,
        value: item.id
      }))
    },
    async fetchJsListFocus() {
      if (!this.jsList.length) {
        this.fetchJsList('')
      }
    },
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.jsArticle.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.model = {
        title: res.title,
        titleEn: res.titleEn,
        jsId: res.jsId,
        articleType: res.articleType,
        showImgFile: res.showImg
          ? {
              uid: uid(),
              status: 'success',
              url: res.showImg
            }
          : null,
        showImgEnFile: res.showImgEn
          ? {
              uid: uid(),
              status: 'success',
              url: res.showImgEn
            }
          : null,
        videoFile: res.videoUrl
          ? {
              uid: uid(),
              status: 'success',
              url: res.videoUrl
            }
          : null,
        context: res.context,
        contextEn: res.contextEn,
        articleDate: res.articleDate,
        contentType: res.contentType || 1,
        outUrl: res.outUrl
      }
      if (res.jsId) {
        this.jsList = [
          {
            label: res.jsName,
            value: res.jsId
          }
        ]
      }
    },
    /* eslint-disable */
    async submit({ showImgFile, showImgEnFile, videoFile, tempFile, ...data }) {
      const params = {
        ...data
      }
      if (showImgFile) {
        params.showImg = showImgFile.response?.data || showImgFile?.url
      }
      if (showImgEnFile) {
        params.showImgEn = showImgEnFile.response?.data || showImgEnFile?.url
      }
      if (videoFile) {
        params.videoUrl = videoFile.response?.data || videoFile?.url
      }
      if (this.id) {
        this.update(params)
      } else {
        this.add(params)
      }
    },
    reset() {
      this.$router.push({
        name: ConstantRouter.DASHBOARD_NEWS
      })
    },
    async add(data) {
      this.loading = true
      const [err] = await this.$serve.jsArticle.add({
        data
      })
      this.loading = false
      if (err) return
      this.$message.success('新闻添加成功')
      this.$router.push({
        name: ConstantRouter.DASHBOARD_NEWS
      })
    },
    async update(data) {
      this.loading = true
      const [err] = await this.$serve.jsArticle.update({
        data: {
          ...data,
          id: this.id
        }
      })
      this.loading = false
      if (err) return
      this.$message.success('新闻修改成功')
      this.$router.push({
        name: ConstantRouter.DASHBOARD_NEWS
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
