var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-news-add"},[(_vm.model.tempFile)?_c('el-alert',{directives:[{name:"copy",rawName:"v-copy",value:(_vm.model.tempFile.response.data),expression:"model.tempFile.response.data"}],staticStyle:{"margin-bottom":"20px"},attrs:{"type":"success","effect":"dark"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',[_vm._v("上传成功, 点击此处可复制该链接"+_vm._s(_vm.model.tempFile.response.data))])]},proxy:true}],null,false,172809797)}):_vm._e(),_c('pro-form',{attrs:{"label-width":"120px","label-suffix":":","model":_vm.model,"fields":_vm.fields,"rules":_vm.rules,"submitter":{
      submitButtonProps: {
        loading: _vm.loading
      },
      submitText: '保存',
      resetText: '返回'
    }},on:{"submit":_vm.submit,"reset":_vm.reset}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }